import PageComponent from '../../common/component/page-component';
import escape from './../../common/template/escape';


class TemplateBuilder extends PageComponent {

	constructor({
					root,
					element,
					jsonUrl = 'https://uni-bielefeld.hr4you.org/api/jobs/json/set/Website',
					contentBlockAttribute = 'contentBlock',
					employmentTypeIdsAttribute = 'employmentTypeIds',
					showSearchButtonAttribute = 'showSearchButton',
					showSearchLinkAttribute = 'showSearchLink',
					searchTargetsAttribute = 'searchTargets'
				}) {
		super({root: root, element: element});
		this.escape = escape;
		this.jsonUrl = jsonUrl;
		this.contentBlockAttribute = contentBlockAttribute;
		this.employmentTypeIdsAttribute = employmentTypeIdsAttribute;
		this.showSearchButtonAttribute = showSearchButtonAttribute;
		this.showSearchLinkAttribute = showSearchLinkAttribute;
		this.searchTargetsAttribute = searchTargetsAttribute;

		this.json = {};
		this.jobPrefix = 'job';
		this.employmentTypeIds = 0;
		this.currentLanguage = 'de';
		this.contactPrefix = 'contact';
		this.activeFacet = null;
		this.resultsVisible = true;

		this.germanTerms = new Map([
			['jobTitle', 'Stellentitel'],
			['jobCustomer', 'Einrichtung'],
			['jobPublishingDateUntil', 'Bewerbungsfrist'],
			['jobKennziffer', 'Kennziffer'],
			['jobTimeLimitation', 'Befristung'],
			['jobProfessorshipLastName', 'Professor*in'],
			['jobOffer', 'zum Stellenangebot'],
			['enteredAs', 'Berufserfahrung'],
			['fieldOfActivity', 'T&auml;tigkeitsbereich'],
		]);
		this.englishTerms = new Map([
			['jobTitle', 'Position'],
			['jobCustomer', 'Institution'],
			['jobPublishingDateUntil', 'Application deadline'],
			['jobKennziffer', 'Job ID'],
			['jobTimeLimitation', 'Time limitation'],
			['jobProfessorshipLastName', 'Professor'],
			['jobOffer', 'to the job offer'],
			['enteredAs', 'Experience'],
			['fieldOfActivity', 'Area'],
		]);
		this.columns = [
			'jobTitle',
			'jobCustomer',
			'jobKennziffer',
			'jobTimeLimitation',
			'jobProfessorshipLastName',
			'jobOffer',
			'jobPublishingDateUntil',
		];
		this.facets = [
			'jobTimeLimitation',
			'jobCustomer',
			'enteredAs',
			'fieldOfActivity'
		];
		this.i18n = {
			de: {
				search: 'Suche',
				showJobs: 'Ausschreibungen anzeigen',
				show: 'Zeige',
				hide: 'Verberge',
				results: 'Ergebnisse',
				result: 'Ergebniss',
			},
			en: {
				search: 'Search',
				showJobs: 'Show jobs',
				show: 'Show',
				hide: 'Hide',
				results: 'Results',
				result: 'Result',
			}
		};
	}


	prepare() {
		this.contentBlock = this.element.querySelector(this.dataSelector(this.contentBlockAttribute));
		this.employmentTypeIds = this.dataAttr(this.element).get(this.employmentTypeIdsAttribute);
		if (this.employmentTypeIds) {
			this.employmentTypeIds = this.employmentTypeIds.map(id => parseInt(id, 10));
			this.currentTargetId = this.employmentTypeIds[0];
		}

		this.searchTargets = this.dataAttr(this.element).get(this.searchTargetsAttribute);
		if (this.searchTargets && this.searchTargets.length > 0) {
			this.currentTargetId = this.searchTargets[0].id;
			this.employmentTypeIds = this.searchTargets.map(target => target.id);
			this.resultsVisible = false;
		}

		this.showSearchButton = this.dataAttr(this.element).get(this.showSearchButtonAttribute);
		if (this.showSearchButton) this.resultsVisible = false; // do not show results on page load if search button is visible

		window.addEventListener('hashchange', () => this.populateTable());
		this.getStoredLocale();
		this.fetchJson(this.currentTargetId);
		this.populateTable();
		document.addEventListener('click', (e) => {
			if ('ubfFacet' in e.target.dataset) {
				const clickedFacet = e.target.dataset.ubfFacet;
				this.activeFacet = (this.activeFacet === clickedFacet) ? null : clickedFacet;
				this.populateTable();
			} else {
				if (!e.target.classList.contains('ubf-facets__link')) {
					if (this.activeFacet !== null) {
						this.activeFacet = null;
						this.populateTable();
					}
				}
			}
			if ('ubfType' in e.target.dataset) {
				this.currentTargetId = parseInt(e.target.dataset.ubfType, 10);
				this.populateTable();
			}
		}, false);
	}


	fetchJson() {
		const http = new XMLHttpRequest();
		http.open('GET', this.jsonUrl, false);
		http.send(null);
		const response = http.responseText;
		this.json = JSON.parse(response);
		http.onerror = (error) => {
			console.error(error);
		};
	}


	createNode(type, classes = [], attributes = [], href = '') {
		const node = document.createElement(type);

		if (classes) {
			for (const nodeClass of classes) {
				node.classList.add(nodeClass);
			}
		}

		if (attributes) {
			for (const attr of attributes) {
				const value = attr.value ? attr.value : '';
				this.dataAttr(node).set(attr.name, value);
			}
		}

		if (type && 'a' && href) {
			node.href = href;
		}

		return node;
	}

	getStoredLocale() {
		if (document.cookie) {
			const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)RoxenConfig\s*=\s*([^;]*).*$)|^.*$/, '$1');
			this.currentLanguage = cookieValue !== '' ? cookieValue : 'de';
		}
	}

	onHashChange() {
		this.populateTable();
	}

	populateTable() {
		if (this.json.HR4YOU_JOBS) {
		//	const entries = this.deI18n([...this.json.HR4YOU_JOBS]).filter(entry => entry.sprachcode === this.currentLanguage);
			const entries = this.deI18n([...this.json.HR4YOU_JOBS]);
			this.fragment = document.createDocumentFragment();
			const applicationTypes = this.groupTypes(entries);

			this.createTable(applicationTypes, this.currentTargetId);

			this.element.innerHTML = '';
			this.element.appendChild(this.fragment);
		}
	}

	deI18n(entries) {
		const language = entries.sprachcode;
		for (const entry of entries) {
			for (const entryKey in entry) {
				if (entry.hasOwnProperty(entryKey)) {
					if (entryKey.endsWith('En')) {
						if (language === 'en') {
							entry[entryKey.substring(0, entryKey.length - 2)] = entry[entryKey];
						}
						delete entry[entryKey];
					}
				}
			}
		}
		return entries;
	}

	groupTypes(entries) {
		const applicationTypes = new Map();

		for (const entry of entries) {
			const applicationData = {
				items: [],
				hasProfessorship: parseInt(entry.jobEmploymentTypeId, 10) === 6,
				employmentTypeId: parseInt(entry.jobEmploymentTypeId, 10)
			};
			const type = parseInt(entry.jobEmploymentTypeId, 10);

			if (!applicationTypes.has(type)) {
				applicationData.items.push(entry);
				applicationTypes.set(type, applicationData);
			} else {
				const typeMapEntry = applicationTypes.get(type);
				typeMapEntry.items.push(entry);
				applicationTypes.set(type, typeMapEntry);
			}
		}
		return applicationTypes;
	}


	createTable(types, type) {
		this.contentBlockCopy = {};
		const applicationData = types.get(parseInt(type, 10));
		const externalTarget = (type === -1);
		this.contentBlockCopy = this.contentBlock.cloneNode(true);
		this.contentBlockCopy.classList.remove('ubf-contentBlock__template');

		if (externalTarget) {
			const searchLabel = this.contentBlockCopy.querySelector(this.dataSelector('searchLabel'));
			if (searchLabel) searchLabel.innerHTML = this.i18n[this.currentLanguage].search;
			// the targets
			const targetsContainer = this.contentBlockCopy.querySelector(this.dataSelector('targetsContainer'));
			if (targetsContainer) targetsContainer.innerHTML = this.createTargetMarkup(this.searchTargets);
			const selectedContainer = this.contentBlockCopy.querySelector(this.dataSelector('selectedContainer'));

			const target = this.searchTargets.find(t => t.id === this.currentTargetId);
			const searchLinkMarkup = `<a href="${target.link}" class="ubf-field__button ubf-button ubf-button">` +
				`<span class="ubf-button__label">${this.i18n[this.currentLanguage].search}</span>` +
				'</a>';
			const searchLinkElement = document.createElement('div');
			searchLinkElement.classList.add('ubf-facets__search-button');
			searchLinkElement.innerHTML = searchLinkMarkup;
			if (selectedContainer) selectedContainer.parentNode.insertBefore(searchLinkElement, selectedContainer.nextSibling);
		} else {
			const searchLabel = this.contentBlockCopy.querySelector(this.dataSelector('searchLabel'));
			if (searchLabel) searchLabel.innerHTML = this.i18n[this.currentLanguage].search;

			// the targets
			const targetsContainer = this.contentBlockCopy.querySelector(this.dataSelector('targetsContainer'));
			if (targetsContainer) targetsContainer.innerHTML = this.createTargetMarkup(this.searchTargets);

			if (applicationData) {
				// sort applicationData.items by jobCustomer property
				applicationData.items.sort((a, b) => (a.jobCustomer.localeCompare(b.jobCustomer) || a.jobKennziffer.localeCompare(b.jobKennziffer)));
				if (this.employmentTypeIds.includes(applicationData.employmentTypeId)) {
					const selected = decodeURIComponent(window.location.hash.substring(1)).split('|').filter(s => s !== '');
					const filters = this.pluckFilter(applicationData, this.facets, selected);
					const filteredData = this.filterData(applicationData, this.facets, selected);

					// the facet filters
					const facetsContainer = this.contentBlockCopy.querySelector(this.dataSelector('facetsContainer'));
					if (facetsContainer) facetsContainer.innerHTML = this.createFacetMarkup(filters);

					// the selected values
					const selectedContainer = this.contentBlockCopy.querySelector(this.dataSelector('selectedContainer'));
					if (selectedContainer) selectedContainer.innerHTML = this.createSelectedValuesMarkup(filters, selected);

					// optional search button
					if (this.showSearchButton) {
						const searchButtonMarkup = `<button class="ubf-field__button ubf-button ubf-button" type="submit"
									aria-pressed="${this.resultsVisible ? 'true' : 'false'}" aria-expanded="${this.resultsVisible ? 'true' : 'false'}">` +
							`<span class="ubf-button__label">
									${this.resultsVisible ? this.i18n[this.currentLanguage].hide : this.i18n[this.currentLanguage].show}
									${filteredData.items.length}
									${filteredData.items.length > 1 ? this.i18n[this.currentLanguage].results : this.i18n[this.currentLanguage].result}
								</span>` +
							'</button>';
						const searchButtonElement = document.createElement('div');
						searchButtonElement.classList.add('ubf-facets__search-button');
						searchButtonElement.innerHTML = searchButtonMarkup;
						this.events.on(searchButtonElement.firstChild, 'click', this.toggleResultDisplay.bind(this));
						if (selectedContainer) selectedContainer.parentNode.insertBefore(searchButtonElement, selectedContainer.nextSibling);
					}

					// search targets
					if (this.searchTargets) {
						const target = this.searchTargets.find(t => t.id === this.currentTargetId);
						const searchLinkMarkup = `<a href="${target.link + window.location.hash}" class="ubf-field__button ubf-button ubf-button">` +
							`<span class="ubf-button__label">
								${this.i18n[this.currentLanguage].showJobs}
							</span>` +
							'</a>';
						const searchLinkElement = document.createElement('div');
						searchLinkElement.classList.add('ubf-facets__search-button');
						searchLinkElement.innerHTML = searchLinkMarkup;
						if (selectedContainer) selectedContainer.parentNode.insertBefore(searchLinkElement, selectedContainer.nextSibling);
					}
					// result list
					if (this.resultsVisible) {
						this.createTableMarkup(type, this.filterData(filteredData, this.facets, selected));
					}
				}
			}
		}
		this.fragment.appendChild(this.contentBlockCopy);
	}
	toggleResultDisplay() {
		this.resultsVisible = !this.resultsVisible;
		this.populateTable();
	}
	filterData(applicationData, facets, selected) {
		const selectedFacets = selected.map(sel => sel.split('_')[0]);
		for (const facetsKey of facets) {
			applicationData.items = applicationData.items.filter(item => {
				// if facet is comma separated list, split values (except for jobCustomer)
				if (facetsKey !== 'jobCustomer' && item[facetsKey].indexOf(', ') !== -1) {
					const splitted = item[facetsKey].split(', ');
					let found = false;
					for (const split of splitted) {
						if (!selectedFacets.includes(facetsKey) || selected.includes(facetsKey + '_' + split)) {
							found = true;
						}
					}
					return found;
				} else {
					return !selectedFacets.includes(facetsKey) || selected.includes(facetsKey + '_' + item[facetsKey]);
				}
			});
		}
		return applicationData;
	}

	pluckFilter(applicationData, facets, selected) {
		const facetsWithValues = [];
		for (const facetsKey of facets) {
			facetsWithValues.push(
				{
					key: facetsKey,
					// pick unique values
					values: [...new Set(applicationData.items.map(
						item => item[facetsKey]
					))].filter(v => v !== '')
				}
			);
		}

		for (const facet of facetsWithValues) {
			const filterdItems = this.filteredItems(applicationData.items, selected, facet.key);

			// when value has a comma, split it and add both part to facet values
			const newValues = [];
			for (const value of facet.values) {
				if (value.indexOf(', ') !== -1) {
					const splitted = value.split(', ');
					for (const split of splitted) {
						newValues.push(split);
					}
				} else {
					newValues.push(value);
				}
			}
			facet.values = [...new Set(newValues)]; // remove duplicates

			// collect facet values for filter including fragments and counts
			facet.values = facet.values.map(value => {
				const isSelected = selected.includes(facet.key + '_' + value);
				const selectedWithout = selected.filter(s => s !== facet.key + '_' + value);
				const selectedWith = selectedWithout.slice();
				selectedWith.push(facet.key + '_' + value);
				const fragments = isSelected ? selectedWithout : selectedWith;

				return {
					label: value,
					selected: isSelected,
					fragment: encodeURIComponent(fragments.join('|')),
					count: this.countItems(filterdItems, facet.key, value)
				};
			});
		}
		return facetsWithValues;
	}
	filteredItems(items, selected, current) {
		const selection = {};
		selected.forEach(s => {
			const key = s.split('_')[0];
			const val = s.split('_')[1];
			if (!(key in selection)) selection[key] = [];
			selection[key].push(val);
		});
		return items.filter( item => selected.every(selectedValue => {
				const key = selectedValue.split('_')[0];
				return key === current || selection[key].includes(item[key]);
			}));
	}
	countItems(array, key, val) {
		return array.reduce((acc, cur) =>
				(
					// wenn string vorhanden
					(cur[key].indexOf(val) !== -1 &&
					// und gleicher länge, außer wenn multivalue
					(cur[key].length === val.length || cur[key].indexOf(', ') !== -1)
				) ? ++acc : acc)
			, 0);
	}
	createTargetMarkup(searchTargets) {
		let targetSelectMarkup = '';
		targetSelectMarkup += '<div class="ubf-facets__target-title js-active"">Jobs filtern</div>';
		if (searchTargets.length > 1) {
			targetSelectMarkup += '<div class="ubf-facets__target-options">';
			for (const searchTarget of this.searchTargets) {
				targetSelectMarkup += '<div class="ubf-field__inputWrapper">';
				targetSelectMarkup += `<input class="ubf-field__input ubf-field__input--radio" type="radio" data-ubf-type="${searchTarget.id}" value="${searchTarget.id}" id="${searchTarget.id}" name="${searchTarget.id}" ${this.currentTargetId === searchTarget.id ? 'checked' : ''}>`;
				targetSelectMarkup += `<label class="ubf-field__label ubf-field__label--radio ubf-facets__targetoption ${this.currentTargetId === searchTarget.id ? 'js-active' : ''}" data-ubf-type="${searchTarget.id}" for="${searchTarget.id}"><span class="ubf-field__labelValue">${searchTarget.label}</span></label>`;
				targetSelectMarkup += '</div>';
			}
			targetSelectMarkup += '</div>';
		}
		return targetSelectMarkup;
	}
	createFacetMarkup(facetsWithValues) {
		let markup = '<div class="ubf-facets__form-label"><span>Filter</span></div><ul class="ubf-facets__facets">';
		const applicationTerms = this.currentLanguage === 'de' ? this.germanTerms : this.englishTerms;
		for (const facet of facetsWithValues) {
			markup += `<li class="ubf-facets__facet ${this.activeFacet === facet.key ? 'js-active' : ''}">`;

			markup += `<div class="ubf-facets__title" data-ubf-facet="${facet.key}">
							<span class="ubf-facets__icon"><span data-type="svg"><canvas width="17" height="22"></canvas><svg viewBox="0 0 17 22" focusable="false" aria-hidden="true"><use xlink:href="#triangle-right"></use></svg></span></span>
							<span>${applicationTerms.get(facet.key)}</span>
						</div>`;
			markup += '<ul class="ubf-facets__values">';
			for (const value of facet.values) {
				markup += `<li class="ubf-facets__value"><a class="ubf-facets__link ${value.count === 0 ? 'js-disabled' : ''} ${value.selected ? 'js-selected' : ''}" href="#${value.fragment}"><span class="ubf-facets__link-label">${value.label}</span> <span class="ubf-facets__link-count">${value.count}</span></a></li>`;
			}
			markup += '</ul>';
			markup += '</li>';
		}
		markup += '</ul>';
		return markup;
	}
	createSelectedValuesMarkup(facetsWithValues, selected) {
		let markup = '<ul class="ubf-facets__selected-list">';
		const applicationTerms = this.currentLanguage === 'de' ? this.germanTerms : this.englishTerms;
		for (const facet of facetsWithValues) {
			for (const value of facet.values.filter(v => v.selected)) {
				markup += `<li class="ubf-facets__selected-value">
								<a class="ubf-facets__selected-link" href="#${value.fragment}">
									<span>${applicationTerms.get(facet.key) + ': ' +value.label}</span>
									<span data-type="svg"><canvas width="13" height="13"></canvas><svg viewBox="0 0 13 13" focusable="false" aria-hidden="true"><use xlink:href="#cancel"></use></svg></span>
								</a>
						   </li>`;
			}
		}
		markup += '</ul>';
		return markup;
	}
	createTableMarkup(type, applicationData) {
		const tableHeadRow = this.contentBlockCopy.querySelector(this.dataSelector('tableHead'));
		const tableBody = this.contentBlockCopy.querySelector(this.dataSelector('tableBody'));
		tableBody.classList.add('ubf-facets__results-table');
		const applications = applicationData.items;
		const hasProfessorship = applicationData.hasProfessorship;

		for (let i = 0; i < applications.length; i++) {
			const application = Object.assign({}, applications[i]);
			const tr = this.createNode('tr');
			const applicationTerms = this.currentLanguage === 'de' ? this.germanTerms : this.englishTerms;
			// for (const [property, term] of applicationTerms) {
			for (const column of this.columns) {
				const property = column;
				const term = applicationTerms.get(column);
				if (property in application) {
					if (property === 'jobPublishingDateUntil') {
						application[property] = this.convertDate(application[property]);
					}

					if (property === 'jobProfessorshipLastName' && hasProfessorship) {
						const lastName = application.jobProfessorshipLastName ? application.jobProfessorshipLastName + ' ' : '';
						const firstName = application.jobProfessorshipFirstName ? application.jobProfessorshipFirstName + ' ' : '';
						const title = application.jobProfessorshipTitle ? application.jobProfessorshipTitle + ' ' : '';
						application.jobProfessorshipLastName = title + firstName + lastName;
					}

					if (i === 0) {
						let th = null;
						if ((!hasProfessorship && property !== 'jobProfessorshipLastName') || hasProfessorship) {
							if (property === 'jobOffer') {
								th = this.createNode('th', ['ubf-ugc__mobileCell']);
							} else {
								th = this.createNode('th');
							}

							th.innerHTML = this.escape(term);
							tableHeadRow.append(th);
						}
					}

					const div = this.createNode('div', [], [{name: 'data-ubf-nested-header'}]);
					let a = null;
					let td = null;
					let p = null;

					if (property === 'jobOffer') {
						a = this.createNode('a', ['ubf-ugc__tableLink', 'ubf-ugc__tableLink--mobile']);
						td = this.createNode('td', ['ubf-ugc__mobileCell'], [{name: 'tableCell'}]);
						p = this.createNode('p', [], []);
					} else {
						a = this.createNode('a', ['ubf-ugc__tableLink']);
						td = this.createNode('td', [], [{name: 'tableCell'}]);
						p = this.createNode('p', [], [{name: 'cellText'}]);
					}


					a.href = application.jobOffer;
					a.setAttribute('target', '_blank');
					const applicationProperty = application[property] ? application[property] : '';
					const escapedProperty = this.escape(applicationProperty);

					if ((!hasProfessorship && property !== 'jobProfessorshipLastName') || hasProfessorship) {
						p.innerHTML = escapedProperty;
						a.append(p);
						a.append(div);
						td.append(a);
						tr.append(td);
					}
				}
			}
			this.dataAttr(tr).set('tableRow', '');
			tableBody.append(tr);
		}
	}

	convertDate(dateString) {
		const date = new Date(dateString);
		const days = ('0' + date.getDate()).slice(-2);
		const months = ('0' + (date.getMonth() + 1)).slice(-2);
		const year = date.getFullYear();
		const convertedDate = days + '.' + months + '.' + year;

		return convertedDate;
	}
}

export default TemplateBuilder;
