import PageComponent from '../../common/component/page-component';
import escape from '../../common/template/escape';



class JobsTeaser extends PageComponent {

	constructor({
		root,
		element,
		institutionAttribute = 'institution',
		entrylevelsAttribute = 'entrylevel',
		activityfieldsAttribute = 'activityfield',
		jsonUrl = 'https://aktuell.uni-bielefeld.de/jobs_api/?institution=',
		contentBlockAttribute = 'contentBlock--jobTeaser',
	}) {
		super({root: root, element: element});
		this.escape = escape;
		this.jsonUrl = jsonUrl;
		this.contentBlockAttribute = contentBlockAttribute;
		this.institutionAttribute=institutionAttribute;
		this.institution='';
		this.entrylevelsAttribute=entrylevelsAttribute;
		this.entrylevels='';
		this.activityfieldsAttribute=activityfieldsAttribute;
		this.activityfields='';
		this.json = {};
		this.currentLanguage = 'de';
	}

	prepare() {
		this.contentBlock = this.element.querySelector(this.dataSelector(this.contentBlockAttribute));
		this.institution = this.dataAttr(this.element).get(this.institutionAttribute);
		this.entrylevels = this.dataAttr(this.element).get(this.entrylevelsAttribute);
		this.activityfields = this.dataAttr(this.element).get(this.activityfieldsAttribute);
		this.getStoredLocale();
		this.fetchJson();
		this.populateJobs();
	}

	fetchJson() {
		const http = new XMLHttpRequest();
		const url = this.jsonUrl + this.institution + '&entrylevels=' + this.entrylevels + '&activity_fields=' + this.activityfields;
		http.open('GET', url, false);
		http.send(null);
		const response = http.responseText;
		this.json = JSON.parse(response);
		http.onerror = (error) => {
			console.error(error);
		};
	}

	getStoredLocale() {
		if (document.cookie) {
			const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)RoxenConfig\s*=\s*([^;]*).*$)|^.*$/, '$1');
			this.currentLanguage = cookieValue !== '' ? cookieValue : 'de';
		}
	}

	populateJobs() {
		const jobList = this.element.querySelector('.ubf-jobTeaser__items');
		let jobListItems = '';
		//
		if (this.json.length>0) {
			this.json.forEach((job) => {
					const jobItem = `<li class="ubf-jobTeaser__item ubf-ugc">
					<a class="ubf-jobTeaser__link" href="${job.jobURL}" target="_blank">
							<div class="ubf-jobTeaser__title" style="font-weight: 400!important;">${job.jobTitle}</div>
					</a>
						</li>`;
					jobListItems=jobListItems+jobItem;
			});
			jobList.innerHTML=jobListItems + jobList.innerHTML;
		} else {
			if (jobList.children.length === 0) {
				//Set unvisible, if there are no jobs
				this.element.setAttribute('style', 'display: none');
			}
		}
	}
}
export default JobsTeaser;
