import {ucFirst} from '../utils/string';


class Factory {

	constructor({container, prefix = '', suffix = '', aliases = {}}) {
		this.container = container;
		this.prefix = prefix;
		this.suffix = suffix;
		this.aliases = {};
	}


	newInstance(name, params = null, setters = {}) {
		return this.container.newInstance(this.prepareName(name), params, setters);
	}


	prepareName(name) {
		name = ucFirst(name);
		if (name in this.aliases) {
			name = this.aliases[name];
		}
		return this.prefix + name + this.suffix;
	}


	has(name) {
		return this.container.hasType(this.prepareName(name));
	}


	setAliases(aliases) {
		this.aliases = Object.assign(this.aliases, aliases);
		return this;
	}


	setAlias(alias, originalName) {
		this.aliases[alias] = originalName;
		return this;
	}


	unsetAlias(alias) {
		if (alias in this.aliases) {
			delete this.aliases[alias];
		}
		return this;
	}

}

export default Factory;
