const listenerMixin = (Base = null) => class extends Base {

	initListeners() {
		this.listeners = {};
	}


	destroyListeners() {
		if (this.listeners) {
			for (const name in this.listeners) {
				if (this.listeners.hasOwnProperty(name) && this.listeners[name]) {
					this.listeners[name].destroy();
				}
			}
			this.listeners = null;
		}
	}

};

export default listenerMixin;
