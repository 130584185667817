import {gsap} from 'gsap';
import Accordion from '../../common/widget/accordion';
import offsetTop from '../../common/utils/offset-top';

class AccordionController extends Accordion {

	constructor({
		root,
		element,
		controlInputAttribute = 'controlInput'
	}) {
		super({root: root, element: element});
		this.controlInputAttribute = controlInputAttribute;
		this.accordionNodes = null;
		this.controls = [];
		this.opened = false;
	}


	prepare() {
		// grab translated label for button
		this.openAllLabel = this.dataAttr(this.element).get('openAllLabel') || 'Alle öffnen';

		const mainPageNode = this.root.querySelector('main');
		if (mainPageNode) {
			// get all accordions on page and allow opening all sections at the same time
			this.accordionNodes = mainPageNode.querySelectorAll(this.dataSelector('component', 'Accordion'));
			this.accordionNodes.forEach((accordionNode) => {
				this.dataAttr(accordionNode).set('allowOnlyOneOpened', false);
				this.addControl(accordionNode); // add the button to all groups
			});
		}
	}

	addControl(node) {
		const index = this.controls.length;
		const control = document.createRange().createContextualFragment(this.getControl(index));
		node.insertBefore(control, node.firstChild);
		const controlInput = document.getElementById('accordionControl_'+index);
		this.listeners.onChange = this.events.on(controlInput, 'change', this.onChange.bind(this));
		this.controls.push(controlInput);
	}

	getControl(index) {
		return `<div aria-label="accessibility tools" class="ubf-field__field ubf-field ubf-field--checkbox ubf-field--accordionController">
			<div class="ubf-field__content">
				<div class="ubf-field__inputWrapper ubf-field__inputWrapper--right">
					<input class="ubf-field__input ubf-field__input--checkbox" type="checkbox" id="accordionControl_${index}" value="1" data-ubf-control-input>
					<label class="ubf-field__label ubf-field__label--checkbox" for="accordionControl_${index}"><span class="ubf-field__labelValue">${this.openAllLabel}</span></label>
				</div>
			</div>
		</div>`;
	}

	onChange(event, target) {
		const isChecked = target.checked;
		this.opened = isChecked;
		this.accordionNodes.forEach((accordionNode) => {
			this.TogglerComponents = this.components.queryComponents(accordionNode, this.dataSelector('component', 'Toggler'));
			this.TogglerComponents.forEach((TogglerComponent) => {
				if (this.opened !== TogglerComponent.isToggled() ) {
					TogglerComponent.toggle();
				}
			});
		});
		// check all controls
		this.controls.forEach((control) => {
			control.checked = isChecked;
		});

		setTimeout(() => {
			// scroll back to group after all is opened
			const posY = offsetTop(target) - 100;
			const isInViewport = target.getBoundingClientRect().top >= 0 &&
				target.getBoundingClientRect().bottom <= (window.innerHeight || document.documentElement.clientHeight);
			if (!isInViewport) gsap.to(window, {duration: 0.5, scrollTo: {y: posY, autoKill: false}});
		}, 500);

	}


	onToggle(event) {
		if (this.busy) {
			event.preventDefault();
			return false;
		}
		if (!this.busy) {
			// prevent the first and manually issue it again, because we want to keep everything in sync
			event.preventDefault();
			this.updateStatus();
			return false;
		}
		return true;
	}
}

export default AccordionController;
