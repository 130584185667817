// promise based timeouts

const waitMs = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const wait = (seconds) => waitMs(seconds * 1000);

const waitFrame = () => new Promise((resolve) => requestAnimationFrame(resolve));

const waitCondition = (condition, interval = 'frame') => new Promise((resolve) => {
	const loop = () => {
		if (condition()) {
			resolve();
		} else {
			if (interval === 'frame') {
				requestAnimationFrame(loop);
			} else {
				setTimeout(loop, interval);
			}
		}
	};
	loop();
});

export {wait, waitMs, waitFrame, waitCondition};
