import SlideLoader from './slide-loader';


class FirstBeforeSlideLoader extends SlideLoader {

    load(slideshow) {
		const slides = slideshow.getSlides().slice();
		if (slides.length) {
			const firstSlide = slides.shift();
			return firstSlide.load().then(() => {
				slideshow.setInteractive();
				slideshow.closeAsyncEvent('interactive');
				const slidePromises = slides.map((slide) => slide.load());
				return Promise.all(slidePromises).then(() => (slideshow));
			});
		}
		return Promise.resolve(slideshow);
    }
}

export default FirstBeforeSlideLoader;
