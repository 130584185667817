import PageComponent from '../../common/component/page-component';


class AppLoader extends PageComponent {

	constructor({
		root,
		element,
		readyClass = 'ready',
	}) {
		super({root: root, element: element});
		this.readyClass = readyClass;
	}


	hide() {
		this.classList(document.body).add(this.readyClass);
		return this.onTransitionEnd(this.element);
	}

}

export default AppLoader;
