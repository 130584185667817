import Container from './common/di/container';
import config from './ubf/config/config';
import './common/utils/focus-options-polyfill';

const container = new Container();
container.setValue('dom/dataAttrPrefix', 'ubf' + '-');
container.setValue('dom/classPrefix', 'ubf' + '-js-');
container.setValue('dom/baseClassPrefix', 'ubf' + '-');
config(container);

const app = container.get('app');

app.init().then(() => app.execute());
