import FontChecker from './font-checker';
import StyleChecker from './style-checker';


export default (di) => {
	di
		.setType({type: FontChecker, name: 'FontChecker'})
		.setType({type: StyleChecker, name: 'StyleChecker', mixins: ['domMixin']})
		.set('preload/fontChecker', di.lazyNew('FontChecker'))
		.set('preload/styleChecker', di.lazyNew('StyleChecker'))
	;
};
