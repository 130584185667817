import AlertContext from './alert-context';
import DefaultContext from './default-context';
import MenuContext from './menu-context';
import ModalContext from './modal-context';


export default (di) => {
	di
		.setType({type: AlertContext, name: 'AlertContext', parent: 'ModalContext'})
		.setType({type: DefaultContext, name: 'DefaultContext', parent: 'Context'})
		.setType({type: MenuContext, name: 'MenuContext', parent: 'Context'})
		.setType({type: ModalContext, name: 'ModalContext', parent: 'Context'})
	;
};
