import PageComponent from '../../common/component/page-component';

class YoutubeShort extends PageComponent {

	constructor({root, element}) {
		super({root: root, element: element});
	}


	prepare() {
		this.active = false;
		this.playerIframe = null;
		this.id = this.dataAttr(this.element).get('youtubeid');
		this.coverUrl = this.dataAttr(this.element).get('cover');
		if (this.coverUrl) {
			this.cover();
		}
		this.coverElement = this.element.querySelector(this.dataSelector('playbutton'));
		this.listeners.playbutton = this.events.on(this.element, this.dataSelector('playbutton'), 'click', this.onClick.bind(this));
		this.listeners.stop = this.events.on(document, 'stopYoutubeShort', this.stopPlaying.bind(this));
	}
	onClick() {
		this.events.trigger(
			document, 'stopYoutubeShort', {
				component: this,
				id: this.id
			}
		);
		this.youtubeEmbed();
	}
	youtubeEmbed() {
		const item = document.createRange().createContextualFragment(
			'<iframe class="ubf-videoreel-item__iframe" src="https://www.youtube-nocookie.com/embed/' + encodeURIComponent(this.id) + '?autoplay=1" allow="fullscreen; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" sandbox="allow-popups allow-same-origin allow-scripts"></iframe>'
		);
		this.element.appendChild(item);
		this.playerIframe = item;
		this.coverElement.classList.add('js-playing');
		this.active = true;
	}
	cover() {
		const cover = document.createRange().createContextualFragment('' +
			'<div class="ubf-videoreel-item__cover" data-ubf-playbutton>' +
			'<figure class="ubf-videoreel-item__picture ubf-picture"><img class="ubf-picture__img ubf-js-loaded" draggable="false" alt="video thumbnail" src="' + encodeURI(this.coverUrl) + '" loading="lazy"></figure>' +
			'<span class="ubf-videoreel-item__videoIcon"><span data-type="svg"><canvas width="32" height="32"></canvas><svg viewBox="0 0 32 32" focusable="false" aria-hidden="true"><use xlink:href="#play"></use></svg></span></span>' +
			'</div>');
		this.element.appendChild(cover);
	}
	stopPlaying(event) {
		const iframe = this.element.querySelector('.ubf-videoreel-item__iframe');
		if (iframe) {
			iframe.remove();
			this.coverElement.classList.remove('js-playing');
			this.active = false;
		}
	}

}

export default YoutubeShort;
