import PageComponent from '../../common/component/page-component';


class UserGeneratedZoomImage extends PageComponent {

	constructor({root, element, modalContentAttribute = 'modalContent', activeClass = 'active'}) {
		super({root: root, element: element});
		this.modalContentAttribute = modalContentAttribute;
		this.activeClass = activeClass;
		this.firstInteraction = true;
		this.selected = false;
	}

	prepare() {
		const img = this.element.querySelector('img');
		const figcaption = this.element.querySelector('figcaption');
		const largeImgSrc = this.element.getAttribute('href');
		this.zoomedItem = this.getZoomedItem(img, largeImgSrc, (figcaption ? figcaption.innerHTML : ''));
		this.zoomedLargeImage = this.getComponent(this.zoomedItem.querySelector(this.dataSelector('largeImage')));
		const modalContent = this.root.querySelector(this.dataSelector(this.modalContentAttribute));
		modalContent.appendChild(this.zoomedItem);
		this.listeners.click = this.events.on(this.element, 'click', this.onClick.bind(this));
		this.listeners.context = this.events.on(this.root, 'context:switchcomplete', this.onContextSwitch.bind(this));
	}


	onClick(event, target) {
		event.preventDefault();
		target.blur();
		const enabled = (getComputedStyle(target).pointerEvents !== 'none');
		if (enabled) {
			if (!this.selected) {
				this.selected = true;
				if (this.firstInteraction) {
					this.firstInteraction = false;
				}
				this.classList(this.zoomedItem).add(this.activeClass);
				this.contexts.push('modal');
			}
		}
	}


	onContextSwitch(event) {
		if (event.detail.previousContext.getName() === 'modal' && this.selected) {
			this.classList(this.zoomedItem).remove(this.activeClass);
			this.selected = false;
		}
	}


	getZoomedItem(smallImg, largeImgSrc, caption = '') {
		smallImg = smallImg.cloneNode();
		const largeImg = smallImg.cloneNode();

		smallImg.setAttribute('class', this.classListParser.buildBaseString(['modalItem__image', 'modalItem__image--small']));
		largeImg.setAttribute('class', this.classListParser.buildBaseString(['modalItem__image', 'modalItem__image--large']));
		if (largeImg.hasAttribute('srcset')) {
			largeImg.removeAttribute('srcset');
		}
		if (largeImg.hasAttribute('sizes')) {
			largeImg.removeAttribute('sizes');
		}
		const data = this.dataAttr(largeImg);
		data.set('autoload', false);
		data.set('srcset', largeImgSrc);
		data.set('largeImage', true);

		const zoomedItem = this.prepareZoomedItem(caption);
		const imagesContainer = zoomedItem.querySelector(this.dataSelector('modalItemImages'));
		imagesContainer.appendChild(smallImg);
		return zoomedItem;
	}


	prepareZoomedItem(caption = '') {
		const tpl = `
			<div class="${this.classListParser.buildBaseString(['modalItem__images'])}" ${this.dataAttrParser.buildString('modalItemImages', '')}>
			</div>
			${caption.length ? `
				<div class="${this.classListParser.buildBaseString(['modalItem__caption'])}">
					${caption}
				</div>
			` : ''}
		`;
		const div = document.createElement('div');
		div.innerHTML = tpl;
		div.setAttribute('class', this.classListParser.buildBaseString(['modal__item', 'modalItem']));
		return div;
	}

}


export default UserGeneratedZoomImage;
