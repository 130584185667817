import {gsap} from 'gsap';
import {ScrollToPlugin} from 'gsap/dist/ScrollToPlugin';
import PageComponent from '../../common/component/page-component';

gsap.registerPlugin(ScrollToPlugin);

class ScrollUp extends PageComponent {

	constructor({
		root,
		element,
		duration = 2
	}) {
		super({root: root, element: element});
		this.defaults.duration = duration;
	}


	prepare() {
		this.listeners.click = this.events.on(this.element, 'click', this.onClick.bind(this));
	}


	onClick(event, target) {
		target.blur();
		event.preventDefault();
		gsap.to(window, {duration: this.dataAttr().get('duration'), scrollTo: {y: 0, autoKill: true}});
	}

}

export default ScrollUp;
