import PageComponent from '../../common/component/page-component';

class StudyForm extends PageComponent {

	constructor({
					root,
					element
				}) {
		super({root: root, element: element});
		this.degree = null;
		this.interest = null;
	}

	prepare() {
		this.options = this.dataAttr(this.element).get('options');
		this.degreeSelect = this.element.querySelector(this.dataSelector('degrees'));
		this.interestSelect = this.element.querySelector(this.dataSelector('interests'));
		this.launchButton = this.element.querySelector(this.dataSelector('launch'));
		console.log(this.options);
		// populate degrees options to degreeSelect element
		//this.addOption(this.degreeSelect, '',  'Abschluss wählen ...', true, true);
		Object.entries(this.options).forEach((degree) => {
			const [key, value] = degree;
			this.addOption(this.degreeSelect, key,  value.label);
		});

		// disable interestSelect Element
		this.interestSelect.disabled = true;
		this.launchButton.disabled = true;

		this.listeners.selectDegree = this.events.on(this.element, this.dataSelector('degrees'), 'change', this.onSelectDegree.bind(this));
		this.listeners.selectInterest = this.events.on(this.element, this.dataSelector('interests'), 'change', this.onSelectInterest.bind(this));
		this.listeners.launch = this.events.on(this.element, this.dataSelector('launch'), 'click', this.onLaunch.bind(this));
	}

	clearOptions(selectElement) {
		selectElement.innerHTML = '';
	}
	addOption(selectElement, key, value, disabled = false, selected = false) {
		const option = document.createElement('option');
		option.value = key;
		option.text = value;
		option.disabled = disabled;
		option.selected = selected;
		selectElement.appendChild(option);
	}

	onSelectDegree(e) {
		// get selected velue from ebent
		this.degree = e.target.value;
		const interests = this.options[this.degree].interests;

		// populate interestSelect element with interest options
		this.clearOptions(this.interestSelect);
		this.addOption(this.interestSelect, '',  'Was suchst Du?', true, true);
		Object.entries(interests).forEach((interest) => {
			const [key, value] = interest;
			this.addOption(this.interestSelect, key,  value.label);
		});
		this.interestSelect.disabled = false;
		this.launchButton.disabled = true;
	}
	onSelectInterest(e) {
		this.interest = this.options[this.degree].interests[e.target.value];
		this.launchButton.disabled = false;
	}
	onLaunch() {
		window.location.href = this.interest.url;
	}
}

export default StudyForm;
