import PageComponent from '../component/page-component';


class SlideshowCursor extends PageComponent {
    constructor({
		root,
		element,
		trackAttribute = 'slideshowCursorTrack',
		cursorAttribute = 'slideshowCursor'
	}) {
		super({root: root, element: element});
		this.trackAttribute = trackAttribute;
		this.cursorAttribute = cursorAttribute;
    }


    prepare() {
		const slideshowId = this.dataAttr().get('for', '');
		if (slideshowId !== '') {
			this.slideshow = this.components.queryComponent(this.root, this.dataSelector('id', slideshowId));
			if (this.slideshow) {
				this.track = this.element.querySelector(this.dataSelector(this.trackAttribute));
				this.cursor = this.element.querySelector(this.dataSelector(this.cursorAttribute));
				this.setup(this.slideshow);
				this.listeners.beforeSwitch = this.events.on(this.slideshow.getElement(), 'slideshow:beforeswitch', this.onBeforeSwitch.bind(this));
				this.listeners.afterSwitch = this.events.on(this.slideshow.getElement(), 'slideshow:afterswitch', this.onAfterSwitch.bind(this));
			}
		}
	}


	onBeforeSwitch(event) {
		if (!event.defaultPrevented) {
			const detail = event.detail;
			this.beforeSwitch(detail.component, detail.currentSlide, detail.newSlide, detail.direction);
		}
	}


	onAfterSwitch(event) {
		if (!event.defaultPrevented) {
			const detail = event.detail;
			this.afterSwitch(detail.component, detail.currentSlide, detail.newSlide, detail.direction);
		}
	}


	// to be overriden
	setup(slideshow) {}

	// to be overriden
	beforeSwitch(slideshow, currentSlide, newSlide, direction) {}

	// to be overriden
	afterSwitch(slideshow, currentSlide, newSlide, direction) {}

}


export default SlideshowCursor;
