import {trim, leftTrim} from '../utils/string';


const SUPPORT_CUSTOM_PROPERTIES = ('CSS' in window) && CSS.supports && CSS.supports('(--varName: "whatever")');


class CssData {

	constructor({element = null, defaults = {}, pseudoElement = 'before', property = 'content', varName = 'jsValues'} = {}) {
		this.element = element;
		this.varName = '--' + varName;
		this.pseudoElement = ':' + leftTrim(pseudoElement, ':');
		this.property = property;
		this.defaults = defaults;
	}


	get(name, defaultValue = undefined) {
		const data = this.getAll();
		const value = (name in data ? data[name] : undefined);
		return (value !== undefined ? value : (name in this.defaults ? this.defaults[name] : defaultValue));
	}


	has(name) {
		return (name in this.getAll());
	}


	getAll() {
		return Object.assign({}, this.defaults, this.fetchData());
	}


	fetchData() {
		let raw;
		if (SUPPORT_CUSTOM_PROPERTIES) {
			raw = getComputedStyle(this.element).getPropertyValue(this.varName);
		} else {
			raw = getComputedStyle(this.element, this.pseudoElement)[this.property];
		}
		raw = trim(raw, '"\' ').replace(/\\"/g, '"');
		let data;
		if (raw.length) {
			try {
				data = JSON.parse(raw);
			} catch (error) {
				console.log('error in parsing CSS data, not a valid JSON');
				console.error(error);
			}
		} else {
			data = {};
		}
		return data;
	}
}


export default CssData;
