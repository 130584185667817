import Context from '../../common/context/context';


class ModalContext extends Context {

	constructor({name, root, popOnKey = 'esc', fixedClass = 'fixed', activeClass = 'active'}) {
		super({name: name, root: root, popOnKey: popOnKey});
		this.activeClass = activeClass;
		this.fixedClass = fixedClass;
	}


	beforeSwitchIn(otherContext) {
		return otherContext;
	}


	switchIn(otherContext) {
		const element = this.getElement();
		this.clickListener = this.events.on(element, 'click', this.onClick.bind(this));
		return this.threeStateTransition(element).add(this.activeClass);
	}


	switchOut(otherContext) {
		const element = this.getElement();
		this.classList(element).add(this.fixedClass);
		if (this.clickListener) {
			this.clickListener.destroy();
		}
		return this.threeStateTransition(element).remove(this.activeClass).then(() => {
			this.classList(element).remove(this.fixedClass);
			return otherContext;
		});
	}


	onClick(event) {
		if (event.target === this.getElement()) {
			this.contexts.pop();
		}
	}

}


export default ModalContext;
