import PageComponent from '../../common/component/page-component';


class GalleryControl extends PageComponent {

	constructor({
		root,
		element,
		activeClass = 'active',
		indexAttribute = 'galleryControlIndex',
		modalContentAttribute = 'modalContent'
	}) {
		super({root: root, element: element});
		this.activeClass = activeClass;
		this.indexAttribute = indexAttribute;
		this.modalContentAttribute = modalContentAttribute;

		this.slideshow = null;
		this.firstClick = true;
		this.slideshowSelected = false;
	}


	prepare() {
		this.slideshow = this.queryComponent(this.dataSelector('id', this.dataAttr().get('for')));
		this.slideshow.setSurviveDetached(true);
		const modalContent = this.root.querySelector(this.dataSelector(this.modalContentAttribute));
		const slideshowElement = this.slideshow.getElement();
		slideshowElement.parentNode.removeChild(slideshowElement);
		modalContent.appendChild(slideshowElement);
		this.slideshow.load();
		this.listeners.click = this.events.on(this.element, this.dataSelector(this.indexAttribute), 'click', this.onGalleryItemClick.bind(this));
		this.listeners.context = this.events.on(this.root, 'context:switchcomplete', this.onContextSwitch.bind(this));
	}


	onGalleryItemClick(event, target) {
		event.preventDefault();
		const index = this.dataAttr(target).get(this.indexAttribute);
		if (this.firstClick) {
			this.firstClick = false;
			this.slideshow.setSurviveDetached(false);
		}
		this.slideshowSelected = true;
		this.slideshow.goTo(index);
		this.classList(this.slideshow.getElement()).add(this.activeClass);
		this.contexts.push('modal');
	}


	onContextSwitch(event) {
		if (event.detail.previousContext.getName() === 'modal' && this.slideshowSelected) {
			this.classList(this.slideshow.getElement()).remove(this.activeClass);
			this.slideshowSelected = false;
		}
	}

}

export default GalleryControl;
