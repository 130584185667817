import NextSlideLoader from './next-slide-loader';
import ZoomableImageSlide from './zoomable-image-slide';

class NextZoomableSlideLoader extends NextSlideLoader {

	loadSiblingSlide(slide, offset) {
		return super.loadSiblingSlide(slide, offset).then(() => {
			const siblingSlide = this.getSiblingSlide(slide, offset);
			if (siblingSlide && siblingSlide instanceof ZoomableImageSlide) {
				return siblingSlide.loadLarge();
			}
			return Promise.resolve();
		});
	}

}

export default NextZoomableSlideLoader;
