import ScrollUp from './scroll-up';
import ShowOnScroll from './show-on-scroll';
import Indicator from './indicator';


export default (di) => {
	di
		.setType({type: ScrollUp, name: 'ScrollUp', parent: 'PageComponent'})
		.setType({type: ShowOnScroll, name: 'ShowOnScroll', parent: 'PageComponent'})
		.setType({type: Indicator, name: 'Indicator', parent: 'PageComponent'})
	;
};
