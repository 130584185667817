import PageComponent from '../../common/component/page-component';

class Test extends PageComponent {

	constructor({
								root,
								element
							}) {
		super({root: root, element: element});
	}


	prepare() {
		this.description = this.element.querySelector(this.dataSelector('test-description'));
		this.listeners.toggle = this.events.on(this.element, this.dataSelector('test-button'), 'click', this.onClick.bind(this));
	}
	onClick() {
		this.description.classList.toggle('ubf-test__description--active');
	}
}

export default Test;
