const unavailableNodeError = 'an "element" or "root" property (of type Element) is required in the current scope to use this method';


const componentsMixin = (Base = class Empty {}) => class extends Base {

	injectComponents(components) {
		this.components = components;
	}


	queryComponents(selector) {
		const node = ('element' in this && this.element instanceof Element ? this.element : ('root' in this && this.root instanceof Element ? this.root : null));
		if (node) {
			return this.components.queryComponents(node, selector);
		}
		throw new Error(unavailableNodeError);
	}


	queryComponent(selector) {
		const node = ('element' in this && this.element instanceof Element ? this.element : ('root' in this && this.root instanceof Element ? this.root : null));
		if (node) {
			return this.components.queryComponent(node, selector);
		}
		throw new Error(unavailableNodeError);
	}


	getComponents(namesOrElements) {
		const node = ('element' in this && this.element instanceof Element ? this.element : ('root' in this && this.root instanceof Element ? this.root : null));
		if (node) {
			return this.components.getComponents(node, namesOrElements);
		}
		throw new Error(unavailableNodeError);
	}


	getComponent(namesOrElements) {
		const node = ('element' in this && this.element instanceof Element ? this.element : ('root' in this && this.root instanceof Element ? this.root : null));
		if (node) {
			return this.components.getComponent(node, namesOrElements);
		}
		throw new Error(unavailableNodeError);
	}

};

export default componentsMixin;
