import domMixin from '../dom/dom-mixin';


class StyleChecker extends domMixin() {

    constructor({classPrefix = '', classSuffix = 'StyleLoaded'} = {}) {
		super();
        this.classPrefix = classPrefix;
        this.classSuffix = classSuffix;
        this.html = document.documentElement || document.querySelector('html');
    }

    checkStyle(name) {
		return new Promise((resolve) => {
			const check = () => {
				if (this.classList(this.html).contains(this.classPrefix + name + this.classSuffix)) {
					resolve();
				} else {
					setTimeout(check, 0);
				}
			};
			check();
		});
    }

}


export default StyleChecker;
