import PageComponent from '../../common/component/page-component';
import {getScrollTop} from '../../common/utils/get-scroll';


class Header extends PageComponent {

	constructor({root, element, mainAttribute = 'main', overlappingClass = 'overlapping'}) {
		super({root: root, element: element});
		this.mainAttribute = mainAttribute;
		this.overlappingClass = overlappingClass;
		this.enabled = false;
		this.triggerMargin = null;
		this.mainOffset = null;
	}


	prepare() {
		this.main = this.root.querySelector(this.dataSelector(this.mainAttribute));
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.updateParams();
	}


	onResize(event) {
		this.updateParams();
	}


	onScroll(event) {
		if (this.enabled) {
			this.updateStatus();
		}
	}


	updateParams() {
		const data = this.cssData().getAll();
		if (data.headerEffectEnabled) {
			this.triggerMargin = parseInt(data.triggerMargin, 10);
			this.mainOffset = this.main.getBoundingClientRect().top + getScrollTop();
			if (!this.enabled) {
				this.listeners.scroll = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
			}
			this.enabled = true;
			this.updateStatus();
		} else {
			if (this.enabled) {
				this.enabled = false;
				this.listeners.scroll.destroy();
				delete this.listeners.scroll;
			}
		}
	}


	updateStatus() {
		const mainPosition = this.mainOffset - getScrollTop();
		this.classList(this.element).toggle(this.overlappingClass, mainPosition <= this.triggerMargin);
	}

}


export default Header;
