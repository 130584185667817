import domMixin from '../dom/dom-mixin';


class SlideAnimator extends domMixin() {

	setup(slideshow) {}


	beforeSwitch(currentSlide, newSlide, direction) {
		return newSlide.beforeSetCurrent();
	}


	switch(currentSlide, newSlide, direction) {
		return Promise.resolve();
	}

	destroy() {}

}


export default SlideAnimator;
