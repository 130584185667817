import {getScrollTop} from '../../common/utils/get-scroll';
// import getOffsetTop from '../../common/utils/offset-top';
import Context from '../../common/context/context';


class DefaultContext extends Context {

	constructor({
		name,
		root,
		popOnKey = false,
		outClass = 'covered',
		otherContextAttribute = 'coveredBy',
		mainMenuAttribute = 'mainMenu',
		footerAttribute = 'footer'
	}) {
		super({name: name, root: root, popOnKey: popOnKey});
		this.prevScrollPos = 0;
		this.outClass = outClass;
		this.otherContextAttribute = otherContextAttribute;
		this.mainMenuAttribute = mainMenuAttribute;
		this.footerAttribute = footerAttribute;
		this.mainMenu = false;
		this.footer = false;
	}


	getMainMenu() {
		if (this.mainMenu === false) {
			this.mainMenu = this.root.querySelector(this.dataSelector(this.mainMenuAttribute));
		}
		return this.mainMenu;
	}


	getFooter() {
		if (this.footer === false) {
			this.footer = this.root.querySelector(this.dataSelector(this.footerAttribute));
		}
		return this.footer;
	}


	getElements() {
		return [
			this.getElement(),
			this.getMainMenu(),
			this.getFooter()
		];
	}


	switchIn(otherContext) {
		for (const node of this.getElements()) {
			if (node) {
				this.classList(node).remove(this.outClass);
				this.dataAttr(node).remove(this.otherContextAttribute);
				node.style.removeProperty('top');
			}
		}
		window.scrollTo(0, this.prevScrollPos);
		return otherContext;
	}


	switchOut(otherContext) {
		const otherContextName = otherContext.getName();
		const mainMenu = this.getMainMenu();
		const scrollPos = getScrollTop();
		this.prevScrollPos = scrollPos;
		const tops = [];
		for (const node of this.getElements()) {
			if (node) {
				const rect = node.getBoundingClientRect();
				tops.push(rect.top);
			}
		}

		let i = 0;
		for (const node of this.getElements()) {
			if (node) {
				this.classList(node).add(this.outClass);
				this.dataAttr(node).set(this.otherContextAttribute, otherContext.getName());
				if (otherContextName === 'modal' || otherContextName === 'alert' || node !== mainMenu) {
					node.style.top = tops[i] + 'px'; // (currentOffset - scrollPos) + 'px';
				}
				i++;
			}
		}
		// const element = this.getElement();
		// const currentOffset = getOffsetTop(element);
		// const elementRect = element.getBoundingClientRect();
		// this.classList(element).add(this.outClass);
		// this.dataAttr(element).set(this.otherContextAttribute, otherContext.getName());
		// element.style.top = elementRect.top + 'px'; // (currentOffset - scrollPos) + 'px';
		window.scrollTo(0, 0);
		return otherContext;
	}

}


export default DefaultContext;
