import getTransitionDuration from '../utils/get-transition-duration';


export default function (element, pseudoElement = null, errorMargin = 100) {
	return new Promise((resolve) => {
		let raised = false;
		const eventName = 'transitionend';
        const duration = getTransitionDuration(element, pseudoElement, true, false) + errorMargin;
		if (duration > 0) {
			const timeout = setTimeout(() => {
				if (!raised) {
					raised = true;
					resolve(element);
				}
			}, duration);

			const internalCallback = (event) => {
				// in some cases the visibility transitionend triggers before the overall duration
				if (event.target === element && event.propertyName !== 'visibility') {
					element.removeEventListener(eventName, internalCallback, false);
					if (!raised) {
						raised = true;
						if (timeout) {
							clearTimeout(timeout);
						}
						resolve(element);
					}
				}
			};
			element.addEventListener(eventName, internalCallback, false);
		} else {
			resolve(element);
		}
	});
}
