import httpRequest from 'superagent';

import Collapsable from '../../common/widget/collapsable';


class DynamicCollapsable extends Collapsable {

	constructor({
		root,
		element,
	}) {
		super({root: root, element: element});
	}


	toggle(value = null, options = {}) {
		const menuId = options.menuId;
		const url = options.srcUrl;
		const isLinebreak =  this.element.childNodes.length === 1 && this.element.innerHTML.match(/\n/g);
		if (this.element.childNodes.length === 0 || isLinebreak) {
			this.fetchMarkup(url, menuId);
		}
		super.toggle();
	}


	fetchMarkup(url, menuId) {
		httpRequest
		.get(url + menuId)
		.set('XMLHttpRequest')
		.then((response) => {
			const markup = response.text;
			this.element.innerHTML = markup;
		})
		.catch(err => {
			console.error(err);
		});
	}
}


export default DynamicCollapsable;
