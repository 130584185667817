import PageComponent from '../component/page-component';


class ContextToggler extends PageComponent {

	constructor({
		root,
		element,
		eventType = 'click',
		toggledClass = 'toggled',
		preventDefault = true,
		blurOnToggle = true,
		contextAttribute = 'forContext',
		targetAction = 'toggle',
		beforeToggleEvent = 'contexttoggler:beforetoggle',
		toggleEvent = 'contexttoggler:toggle',
		enabledByCss = false,
		cssEnabledProperty = 'togglerEnabled'
	}) {
		super({root: root, element: element});
		this.defaults.eventType = eventType;
		this.defaults.toggledClass = toggledClass;
		this.defaults.preventDefault = preventDefault;
		this.defaults.blurOnToggle = blurOnToggle;
		this.defaults.contextAttribute = contextAttribute;
		this.defaults.targetAction = targetAction;
		this.defaults.beforeToggleEvent = beforeToggleEvent;
		this.defaults.toggleEvent = toggleEvent;
		this.defaults.enabledByCss = enabledByCss;
		this.defaults.cssEnabledProperty = cssEnabledProperty;
		this.toggled = null; // will be a boolean after prepare()
		this.busy = false;
		this.contextName = null;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.contextName = data.contextAttribute in data ? data[data.contextAttribute] : null;
		if (this.contextName) {
			this.toggled = this.contexts.getCurrentContext().getName() === this.contextName;
		} else {
			this.toggled = false;
		}

		this.listeners.toggle = this.events.on(this.element, data.eventType, this.onToggle.bind(this));
		this.listeners.contextSwitch = this.events.on(this.root, 'context:beforeswitch', this.onBeforeContextSwitch.bind(this));
	}


	onToggle(event, target) {
		const data = this.dataAttr().getAll();
		if (data.preventDefault) {
			event.preventDefault();
		}
		if (data.blurOnToggle) {
			target.blur();
		}
		if (!data.enabledByCss || this.cssData().get(data.cssEnabledProperty, true)) {
			this.toggle();
		}
	}


	onBeforeContextSwitch(event) {
		if (event.detail.currentContext.getName() === this.contextName || event.detail.newContext.getName() === this.contextName) {
			this.classList(this.element).toggle(this.dataAttr().get('toggledClass'));
			if (!this.toggled) {
				this.element.setAttribute('aria-expanded', true);
			} else {
				this.element.setAttribute('aria-expanded', false);
			}
			this.toggled = !this.toggled;
		}
	}


	toggle(value = null) {
		const data = this.dataAttr().getAll();
		return Promise.resolve().then(() => {
			if (this.busy) {
				return false;
			}
			this.busy = true;
			value = (value === null ? !this.toggled : !!value);
			const beforeEvent = this.events.trigger(this.element, data.beforeToggleEvent, {component: this, toggled: this.toggled, intention: value});
			if (beforeEvent.defaultPrevented) {
				this.busy = false;
				return false;
			}
			return this.contexts[data.targetAction](this.contextName).then(() => true);
		}).then((complete) => {
			if (complete) {
				this.busy = false;
				this.events.trigger(this.element, data.toggleEvent, {component: this, toggled: value});
			}
		});
	}


	isBusy() {
		return this.busy;
	}


	isToggled() {
		return this.toggled;
	}

}

export default ContextToggler;
