import keymaster from 'keymaster';
import domMixin from '../dom/dom-mixin';
import componentConstants from '../component/_constants';


class Contexts extends domMixin() {

	constructor({root = document.body} = {}) {
		super();
		this.root = root;
		this.contexts = new Map();
		this.swapTransitions = new Map();
		this.currentContext = null;
		this.defaultContext = null;
		this.stack = [];
		this.promise = Promise.resolve();
	}


	injectContextFactory(contextFactory) {
		this.contextFactory = contextFactory;
	}


	// currentName and / or newName can also be '*' for a "catch-all" match
	setSwapTransition(currentName, newName, transition) {
		this.swapTransitions.set(currentName + '>' + newName, transition);
		return this;
	}


	hasContext(name) {
		return (this.contexts.has(name) || this.contextFactory.has(name));
	}


	getContext(name) {
		if (this.contexts.has(name)) {
			return this.contexts.get(name);
		}
		const contextClass = this.contextFactory.has(name) ? name : '';
		const context = this.contextFactory.newInstance(contextClass, {name: name, root: this.root});
		this.contexts.set(name, context);
		return context;
	}


	setDefaultContext(name, activate = true) {
		const context = this.getContext(name);
		this.currentContext = context;
		this.defaultContext = context;
		this.updateRoot();
		keymaster.setScope(context.getName());
		if (activate) {
			context.activate();
		}
		return this;
	}


	getDefaultContext() {
		return this.defaultContext;
	}


	getCurrentContext() {
		return this.currentContext;
	}


	getContexts() {
		return this.contexts;
	}


	push(name) {
		if (this.promise === null) {
			this.promise = Promise.resolve();
		}
		this.promise = this.promise.then(() => {
			const context = this.getContext(name);
			if (context.getName() !== this.currentContext.getName()) {
				this.stack.push(this.currentContext);
				return this.switch(this.currentContext, context);
			}
			return Promise.resolve();
		});
		return this.promise;
	}


	pop() {
		if (this.promise === null) {
			this.promise = Promise.resolve();
		}
		this.promise = this.promise.then(() => {
			if (this.stack.length) {
				const prev = this.stack.pop();
				return this.switch(this.currentContext, prev);
			}
			return Promise.resolve();
		});
		return this.promise;
	}


	toggle(name) {
		const context = this.getContext(name);
		return (context.getName() !== this.currentContext.getName() ? this.push(name) : this.pop());
	}


	popByKey() {
		this.pop();
	}


	switch(currentContext, newContext) {
		const currentName = currentContext.getName();
		const newName = newContext.getName();
		const transitionNames = [
			currentName + '>' + newName,	// exact match
			currentName + '>*',				// catch all new
			'*>' + newName, 				// catch all current
			'*>*'							// catch all
		];
		this.events.trigger(this.root, 'context:beforeswitch', {
			contexts: this,
			currentContext: currentContext,
			newContext: newContext
		});
		let transition = Promise.resolve();
		let foundTransition = false;
		for (const transitionName of transitionNames) {
			if (this.swapTransitions.has(transitionName)) {
				foundTransition = true;
				transition = transition.then(() => this.swapTransitions.get(transitionName).execute(currentContext, newContext));
				break;
			}
		}
		if (!foundTransition) {
			if ('beforeSwitchIn' in newContext) {
				transition = transition.then(() => newContext.beforeSwitchIn(currentContext));
			}
			if ('beforeSwitchOut' in currentContext) {
				transition = transition.then(() => currentContext.beforeSwitchOut(newContext));
			}
			if ('switchOut' in currentContext) {
				transition = transition.then(() => currentContext.switchOut(newContext));
			}
			if ('switchIn' in newContext) {
				transition = transition.then(() => newContext.switchIn(currentContext));
			}
			if ('afterSwitchIn' in newContext) {
				transition = transition.then(() => newContext.afterSwitchIn(currentContext));
			}
			if ('afterSwitchOut' in currentContext) {
				transition = transition.then(() => currentContext.afterSwitchOut(newContext));
			}
		}
		this.promise = transition.then(() => {
			this.currentContext = newContext;
			keymaster.setScope(newName);
			this.updateRoot();
			currentContext.deactivate();
			newContext.activate();
			if (currentContext.getPopOnKey()) {
				keymaster.unbind(currentContext.getPopOnKey(), currentName);
			}
			if (newContext.getPopOnKey() !== false) {
				keymaster(newContext.getPopOnKey(), newName, () => this.pop());
			}

			this.events.trigger(this.root, 'context:switchcomplete', {
				contexts: this,
				previousContext: currentContext,
				currentContext: newContext
			});
			return Promise.resolve(currentContext, newContext);
		});
		return this.promise;
	}


	ready() {
		return this.promise;
	}


	updateRoot() {
		this.dataAttr(this.root).set(componentConstants.currentContextAttribute, this.currentContext.getName());
		return this;
	}

}


export default Contexts;
