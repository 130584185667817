import FontFaceObserver from 'fontfaceobserver';


class FontChecker {
    constructor({timeout = 3000, classPrefix = 'font-', classSuffix = '-done', loadedClassSuffix = '-loaded'} = {}) {
        this.timeout = timeout;
        this.classPrefix = classPrefix;
        this.classSuffix = classSuffix;
        this.loadedClassSuffix = loadedClassSuffix;
        this.index = new Map();
        this.html = document.documentElement || document.querySelector('html');
    }

    checkFont(name, options) {
        if (!options) {
            options = {};
        }
        const internalName = this.expandName(name, options);
        let pos;
        if (this.index.has(internalName)) {
            pos = this.index.get(internalName);
        }  else {
             pos = this.index.size;
             this.index.set(internalName, pos);
		}
		return new Promise((resolve) => {
			if (!this.html.classList.contains(this.classPrefix + pos + this.classSuffix)) {
				const font = new FontFaceObserver(name, options);
				font.load().then(() => {
						this.html.classList.add(this.classPrefix + pos + this.classSuffix, this.classPrefix + pos + this.loadedClassSuffix);
						resolve();
					},
					() => {
						this.html.classList.add(this.classPrefix + pos + this.classSuffix);
						console.log('Font ' +  name + ' (' + internalName + ') is not available');
						resolve();
					}
				);
			} else {
				resolve();
			}
		});
    }


    expandName(name, options) {
        return name +
            '-w-' + ('weight' in options ? options.weight : 'normal') +
            '-s-' + ('style' in options ? options.style : 'normal') +
            '-t-' + ('stretch' in options ? options.stretch : 'normal')
        ;
    }
}


export default FontChecker;
