import PageComponent from '../../common/component/page-component';


class FileUploadField extends PageComponent {

	constructor({
		root,
		element,
		inputAttribut = 'uploadInput',
		infoFieldAttribut = 'uploadInfo'
	}) {
		super({root: root, element: element});
		this.inputAttribut = inputAttribut;
		this.infoFieldAttribut = infoFieldAttribut;
	}

	prepare() {
		const input = this.element.querySelector(this.dataSelector(this.inputAttribut));
		this.infoField = this.element.querySelector(this.dataSelector(this.infoFieldAttribut));
		this.listeners.change = this.events.on(input, 'change', this.onChange.bind(this));
	}

	onChange(event, target) {
		const value = target.value;
		const sanatized = value.replace(/.*[/\\]/, '');
		this.infoField.innerHTML = sanatized;
	}
}


export default FileUploadField;
