function toMs(value) {
    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
        return 0;
	}
    return (value.indexOf('ms') === -1 && value.indexOf('s') > -1 ? numericValue * 1000 : numericValue);
}


export default function (element, pseudoElement = null, includeDelay = true, seconds = true) {
    const style = getComputedStyle(element, pseudoElement);
    let duration = 0;
	let delay = 0;
    const durations = style['transition-duration'].split(/\s*,\s/);
    for (const value of durations) {
		duration = Math.max(duration, toMs(value));
	}
	if (includeDelay) {
		const delays = style['transition-delay'].split(/\s*,\s/);
		for (const value of delays) {
			delay = Math.max(delay, toMs(value));
		}
	}
	const total = duration + delay;
	return (seconds ? total / 1000 : total);
}
