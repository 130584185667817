import httpRequest from 'superagent';
import PageComponent from '../component/page-component';


class CalendarEvents extends PageComponent {

	constructor({
		root,
		element,
		paramsAttribute = 'eventsParams',
		modeAttribute = 'eventsMode',
		defaultMode = 'replace'
	}) {
		super({root: root, element: element});
		this.paramsAttribute = paramsAttribute;
		this.modeAttribute = modeAttribute;

		this.defaults[modeAttribute] = defaultMode;

		this.mode = null;
	}


	prepare() {
		const data = this.dataAttr();
		this.mode = data.get(this.modeAttribute);
		const calendarId = data.get('for', null);
		if (calendarId) {
			this.calendar = this.components.queryComponent(this.root, this.dataSelector('id', calendarId));
			if (this.calendar) {
				this.listeners.select = this.events.on(this.calendar.getElement(), this.calendar.getSelectEvent(), this.onCalendarSelect.bind(this));
			}
		}
	}


	onCalendarSelect(event) {
		const eventData = event.detail;
		eventData.originalEvent.preventDefault();
		const selectedTarget = eventData.selectedTarget;
		const url = selectedTarget.hasAttribute('href') ? selectedTarget.getAttribute('href') : null;
		if (url !== null) {
			const params = this.dataAttr(selectedTarget).get(this.paramsAttribute, {});
			this.getContent(url, params).then((response) => {
				this.replaceContent(response, this.mode);
			});
		}
	}


	getContent(url, params = {}) {
		return httpRequest
			.get(url)
			.set('X-Requested-With', 'XMLHttpRequest')
			.query(params)
			.then((response) => response.text)
		;
	}


	replaceContent(content, mode) {
		if (mode === 'replace') {
			const div = document.createElement('div');
			div.innerHTML = content;
			const replacement = div.querySelector(this.dataSelector('component', 'CalendarEvents'));
			if (replacement) {
				content = replacement.innerHTML;
				this.element.innerHTML = content;
			} else {
				throw new Error('unable to find the content root');
			}
		} else {
			this.element.innerHTML = content;
		}
	}

}


export default CalendarEvents;
