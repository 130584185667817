class ClassList {

	constructor({parser, element = null, prefix = undefined} = {}) {
		this.parser = parser;
		this.element = element;
		this.prefix = prefix;
	}


	add(...classes) {
		this.element.classList.add(...this.parser.addPrefix(classes, this.prefix));
		return this;
	}


	remove(...classes) {
		this.element.classList.remove(...this.parser.addPrefix(classes, this.prefix));
		return this;
	}


	item(number) {
		let item = this.element.classList.item(number);
		if (item) {
			item = this.parser.removePrefix(item, this.prefix);
		}
		return item;
	}


	toggle(className, force) {
		className = this.parser.addPrefix([className], this.prefix)[0];
		if (force !== undefined) {
			this.element.classList.toggle(className, force);
		} else {
			this.element.classList.toggle(className);
		}
		return this;
	}


	contains(className) {
		className = this.parser.addPrefix([className], this.prefix)[0];
		return this.element.classList.contains(className);
	}

}


export default ClassList;
