import UbfApp from './ubf-app';
import AppLoader from './app-loader';


export default (di) => {
	di
		.setType({
			type: UbfApp, name: 'UbfApp', parent: 'App', params: {
				requiredStyles: ['default'],
				requiredFonts: [
					// {
					// 	name: 'ubf-grotesk-condensed',
					// 	options: {weight: '700'}
					// },
					// {
					// 	name: 'ubf-grotesk',
					// 	options: {weight: '400'}
					// },
					// {
					// 	name: 'ubf-grotesk',
					// 	options: {weight: '500'}
					// }
				]
			}, setters: {
				injectHashScroller: di.lazyNew('HashScroller')
			}, postCreateCalls: [(app) => {
			}]
		})
		.set('app', di.lazyNew('UbfApp'))

		.setType({type: AppLoader, name: 'AppLoader', parent: 'PageComponent'})
	;
};
