import domMixin from '../dom/dom-mixin';
import componentsMixin from './components-mixin';
import componentConstants from './_constants';


class LazyLoader extends componentsMixin(domMixin()) {

	constructor({
		defaultThreshold = 0,
		defaultMargin = '50% 0px 50% 0px'
	}) {
		super();
		this.defaultThreshold = defaultThreshold;
		this.defaultMargin = defaultMargin;
		this.observedMaps = new Map();
	}

	injectIntersectionObservers(intersectionObservers) {
		this.intersectionObservers = intersectionObservers;
	}

	init(root) {
		this.root = root;
		this.selector = this.dataSelector(componentConstants.componentAttribute) + this.dataSelector(componentConstants.autoloadAttribute, componentConstants.lazyLoadValue);

		this.componentInitListener = this.events.on(this.root, this.selector, componentConstants.componentInitEvent, this.onComponentInit.bind(this));
		this.componentDestroyListener = this.events.on(this.root, componentConstants.componentDestroyEvent, this.onComponentDestroy.bind(this));
	}


	destroy() {
		this.componentInitListener.destroy();
		this.componentDestroyListener.destroy();
	}


	onComponentInit(event, target) {
		if (event.target === target) {
			const dataAttr = this.dataAttr(target);
			const threshold = dataAttr.get(componentConstants.lazyLoadThresholdAttribute, this.defaultThreshold);
			const margin = dataAttr.get(componentConstants.lazyLoadMarginAttribute, this.defaultMargin);
			const id = this.getIdFromParams(threshold, margin);
			const observer = this.intersectionObservers.initObserver(id, threshold, this.onIntersect.bind(this), margin);
			this.observedMaps.set(target, observer);
			observer.observe(target);
		}
	}


	onComponentDestroy(event) {
		if (this.observedMaps.has(event.detail.element)) {
			const target = event.detail.element;
			this.observedMaps.get(target).unobserve(target);
			this.observedMaps.delete(target);
		}
	}


	onIntersect(entries, observer) {
		for (let i = 0, end = entries.length; i < end; i++) {
			if (entries[i].isIntersecting) {
				const target = entries[i].target;
				observer.unobserve(target);
				this.observedMaps.delete(target);
				const component = this.components.getComponent(this.root, target);
				if (component && 'load' in component && component.load instanceof Function) {
					component.load();
				}
			}
		}
	}


	getIdFromParams(threshold, margin) {
		if (Array.isArray(threshold)) {
			threshold = threshold.join(',');
		}
		return threshold + '-' + margin;
	}

}

export default LazyLoader;
