import Accordion from './accordion';
import AlertToggler from './alert-toggler';
import Calendar from './calendar';
import CalendarEvents from './calendar-events';
import Collapsable from './collapsable';
import ContentSlider from './content-slider';
import ContentSliderGsap from './content-slider-gsap';
import ContentSliderNavigation from './content-slider-navigation';
import ContextToggler from './context-toggler';
import Tabs from './tabs';
import Toggler from './toggler';
import TogglerController from './toggler-controller';
import PreviewsSlider from './previews-slider';
import PreviewsSliderNavigation from './previews-slider-navigation';

export default (di) => {
	di
		.setType({type: Accordion, name: 'Accordion', parent: 'PageComponent'})
		.setType({type: AlertToggler, name: 'AlertToggler', parent: 'ContextToggler'})
		.setType({type: Calendar, name: 'Calendar', parent: 'PageComponent'})
		.setType({type: CalendarEvents, name: 'CalendarEvents', parent: 'PageComponent'})
		.setType({type: Collapsable, name: 'Collapsable', parent: 'PageComponent'})
		.setType({type: ContentSlider, name: 'ContentSlider', parent: 'PageComponent'})
		.setType({type: ContentSliderGsap, name: 'ContentSliderGsap', parent: 'PageComponent'})
		.setType({type: ContentSliderNavigation, name: 'ContentSliderNavigation', parent: 'PageComponent'})
		.setType({type: ContextToggler, name: 'ContextToggler', parent: 'PageComponent'})
		.setType({type: PreviewsSlider, name: 'PreviewsSlider', parent: 'PageComponent'})
		.setType({type: PreviewsSliderNavigation, name: 'PreviewsSliderNavigation', parent: 'PageComponent'})
		.setType({type: Tabs, name: 'Tabs', parent: 'PageComponent'})
		.setType({type: Toggler, name: 'Toggler', parent: 'PageComponent'})
		.setType({type: TogglerController, name: 'TogglerController', parent: 'PageComponent'})
	;
};
