import PageComponent from '../../common/component/page-component';


class SearchToggler extends PageComponent {

	constructor({
		root,
		element,
	}) {
		super({root: root, element: element});
	}


	prepare() {
		this.listeners.toggle = this.events.on(document, 'click', this.onClick.bind(this));
		this.listeners.toggle = this.events.on(this.element, 'focus', this.onFocus.bind(this));
	}

	onFocus(event) {
		this.element.classList.add('active');
	}


	onClick(event) {
		const target = event.target;
		if (this.element.contains(target)) {
			this.element.classList.add('active');
			this.element.getElementsByTagName('input')[0].focus();
		} else {
			this.element.classList.remove('active');
			this.element.getElementsByTagName('input')[0].blur();
		}
	}
}

export default SearchToggler;
