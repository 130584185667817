import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {isString} from '../utils/types';
import PageComponent from '../component/page-component';

dayjs.extend(customParseFormat);


class Calendar extends PageComponent {

	constructor({
		root,
		element,
		autodiscoverLocale = true,
		autodiscoverLocaleAttribute = 'autodiscoverLocale',
		dateFormat = 'YYYY-MM-DD',
		dateFormatAttribute = 'dateFormat',
		dateAttribute = 'calendarDate',
		monthActionAttribute = 'calendarGoToMonth',
		daysContainerAttribute = 'calendarDays',
		dayAttribute = 'calendarDate',
		monthValueFormatAttribute = 'calendarMonthValueFormat',
		selectableAttribute = 'calendarSelectable',
		selectEventAttribute = 'selectEvent',
		selectEvent = 'calendar:select',
		selectedClassAttribute = 'selectedClass',
		selectedClass = 'selected',
		blurOnSelectAttribute = 'blurOnSelect',
		blurOnSelect = true,
		attrReplacementsAttribute = 'calendarAttrReplacements',
	}) {
		super({root: root, element: element});
		this.dateFormatAttribute = dateFormatAttribute;
		this.autodiscoverLocaleAttribute = autodiscoverLocaleAttribute;
		this.dateAttribute = dateAttribute;
		this.monthActionAttribute = monthActionAttribute;
		this.daysContainerAttribute = daysContainerAttribute;
		this.dayAttribute = dayAttribute;
		this.monthValueFormatAttribute = monthValueFormatAttribute;
		this.selectableAttribute = selectableAttribute;
		this.selectEventAttribute = selectEventAttribute;
		this.selectedClassAttribute = selectedClassAttribute;
		this.blurOnSelectAttribute = blurOnSelectAttribute;
		this.attrReplacementsAttribute = attrReplacementsAttribute;

		this.defaults[autodiscoverLocaleAttribute] = autodiscoverLocale;
		this.defaults[dateFormatAttribute] = dateFormat;
		this.defaults[selectEventAttribute] = selectEvent;
		this.defaults[selectedClassAttribute] = selectedClass;
		this.defaults[blurOnSelectAttribute] = blurOnSelect;

		this.locale = 'en';
		this.date = null;
		this.daysContainer = null;
		this.dayModel = null;
		this.dateFormat = null;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.dateFormat = data[this.dateFormatAttribute];
		this.selectEvent = data[this.selectEventAttribute];
		this.selectedClass = data[this.selectedClassAttribute];
		this.blurOnSelect = data[this.blurOnSelectAttribute];

		// try to discover the language looking for lang="..." in the ancestors
		if (data[this.autodiscoverLocaleAttribute]) {
			let item = this.element;
			while (item) {
				if (item.hasAttribute('lang')) {
					this.locale = item.getAttribute('lang');
					break;
				} else {
					item = item.parentNode;
				}
			}
		}
		this.date = this.dataAttribute in data ? dayjs(data[this.dataAttribute], this.dateFormat) : dayjs();
		this.daysContainer = this.element.querySelector(this.dataSelector(this.daysContainerAttribute));

		// we assume that the first rendering is done server-side,
		// then we use the first day element as a model to generate the new ones on demand,
		// without needing a separated template
		this.dayModel = this.daysContainer.querySelector(this.dataSelector(this.dayAttribute)).cloneNode(true);
		this.listeners.monthAction = this.events.on(this.element, this.dataSelector(this.monthActionAttribute), 'click', this.onMonthAction.bind(this));
		this.listeners.select = this.events.on(this.element, this.dataSelector(this.selectableAttribute), 'click', this.onSelect.bind(this));
	}


	onSelect(event, target) {
		const selectEvent = this.events.trigger(this.element, this.selectEvent, {
			component: this,
			selectedTarget: target,
			originalEvent: event
		});
		if (!selectEvent.defaultPrevented) {
			if (this.blurOnSelect) {
				target.blur();
			}
			const previous = this.element.querySelector(this.classSelector(this.selectedClass));
			if (previous) {
				this.classList(previous).remove(this.selectedClass);
			}
			this.classList(target).add(this.selectedClass);
		}
	}


	onMonthAction(event, target) {
		const action = this.dataAttr(target).get(this.monthActionAttribute);
		const refDate = this.date.startOf('month');
		let targetDate;
		switch (action) {
			case 'prev':
				targetDate = refDate.subtract(1, 'month');
				break;

			case 'next':
				targetDate = refDate.add(1, 'month');
				break;

			default:
				targetDate = dayjs(action, this.dateFormat).startOf('month');
		}

		this.update(targetDate);
	}


	getSelectEvent() {
		return this.selectEvent;
	}


	getLocale() {
		return this.locale;
	}


	setLocale(locale) {
		this.locale = locale;
	}


	// swap the list of days of the month with a new one
	update(date) {
		if (isString(date)) {
			date = dayjs(date, this.dateFormat);
		}

		const fragment = this.getMonthFragment(date);
		this.daysContainer.innerHTML = '';
		this.daysContainer.appendChild(fragment);

		// setting month values
		const monthElements = this.element.querySelectorAll(this.dataSelector(this.monthValueFormatAttribute));
		for (let j = 0; j < monthElements.length; j++) {
			const element = monthElements.item(j);
			const format = this.dataAttr(element).get(this.monthValueFormatAttribute);
			element.textContent = date.locale(this.locale).format(format);
		}

		this.date = date;
	}


	getMonthFragment(date) {
		if (isString(date)) {
			date = dayjs(date, this.dateFormat);
		}

		const fragment = document.createDocumentFragment();
		const firstDay = parseInt(date.format('D'), 10);
		const lastDay = parseInt(date.endOf('month').format('D'), 10);
		let currentDate = date;
		for (let i = firstDay; i <= lastDay; i++) {
			const dayElement = this.dayModel.cloneNode(true);
			const data = this.dataAttr(dayElement);

			// replacing attributes with patterns
			const items = Array.prototype.slice.call(dayElement.querySelectorAll(this.dataSelector(this.attrReplacementsAttribute)));
			if (data.has(this.attrReplacementsAttribute)) {
				items.push(dayElement);
			}
			for (let j = 0; j < items.length; j++) {
				const item = items[j];
				const replacements = this.dataAttr(item).get(this.attrReplacementsAttribute, null);
				if (replacements !== null) {
					for (const attrName in replacements) {
						if (replacements.hasOwnProperty(attrName)) {
							const newValue = this.processAttrValue(replacements[attrName], currentDate);
							if (attrName === 'content') {
								item.textContent = newValue;
							} else {
								item.setAttribute(attrName, newValue);
							}
						}
					}
				}
			}

			fragment.appendChild(dayElement);
			currentDate = currentDate.add(1, 'day');
		}
		return fragment;
	}


	processAttrValue(value, currentDate) {
		return value.replace(/\{\{([^}]+)\}\}/gi, (full, format) => {
			let result;
			switch (format) {
				case 'calendarIsToday':
					result = currentDate.format(this.dateFormat) === dayjs().format(this.dateFormat) ? 'true' : 'false';
					break;

				default:
				result = currentDate.locale(this.locale).format(format);
			}
			return result;
		});
	}


}


export default Calendar;
