import 'intersection-observer';

class IntersectionObservers {
	constructor() {
		this.observers = new Map();
		this.callbacks = new WeakMap();
	}

	initObserver(id, threshold, callback, margin = '0px 0px 0px 0px', root = null) {
		if (!this.observers.has(id)) {
			if (!Array.isArray(threshold)) {
				threshold = [threshold];
			}
			const observer = new IntersectionObserver(this.onIntersect.bind(this), {
				root: root,
				rootMargin: margin,
				threshold: threshold,
			});
			const callbacks = [];
			this.callbacks.set(observer, callbacks);
			this.observers.set(id, observer);
		}
		const observer = this.observers.get(id);
		const callbacks = this.callbacks.get(observer);
		callbacks.push(callback);
		return observer;
	}

	onIntersect(entries, observer) {
		const callbacks = this.callbacks.get(observer);
		if (callbacks) {
			for (const callback of callbacks) {
				callback(entries, observer);
			}
		}
	}

	destroyObserver(id) {
		if (this.observers.has(id)) {
			this.observers.get(id).disconnect();
			this.observers.delete(id);
		}
		return this;
	}

	hasObserver(id) {
		return this.observers.has(id);
	}

	getObserver(id) {
		if (!this.observers.has(id)) {
			throw new Error('Observer not defined');
		}
		return this.observers.get(id);
	}

	observe(id, elements) {
		return this.changeObservation(id, elements, true);
	}

	unobserve(id, elements) {
		return this.changeObservation(id, elements, false);
	}

	changeObservation(id, elements, observe) {
		const observer = this.getObserver(id);
		if (elements instanceof Element) {
			elements = [elements];
		}
		const method = observe ? 'observe' : 'unobserve';
		for (const element of elements) {
			observer[method](element);
		}
		return this;
	}
}

export default IntersectionObservers;
