export default {
	componentAttribute: 'component',
	componentContextAttribute: 'componentContext',
	contextAttribute: 'context',
	currentContextAttribute: 'currentContext',
	componentInitEvent: 'component:init',
	componentDestroyEvent: 'component:destroy',
	autoloadAttribute: 'autoload',
	lazyLoadValue: 'lazy',
	lazyLoadMarginAttribute: 'lazyLoadMargin',
	lazyLoadThresholdAttribute: 'lazyLoadThreshold'
};
