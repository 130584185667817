import PageComponent from '../../common/component/page-component';


// the purpose of this component is to replicate the table headers inside each cell,
// in order to show a label > value structure on small screens where the table layout is switching to a linear block layout
class UserGeneratedTable extends PageComponent {

	constructor({root, element, cellProcessedAttribute = 'processed', nestedHeaderAttribute = 'nestedHeader'}) {
		super({root: root, element: element});
		this.cellProcessedAttribute = cellProcessedAttribute;
		this.nestedHeaderAttribute = nestedHeaderAttribute;
	}

	prepare() {
		const containsNestedTables = !! this.element.querySelector('table');
		const headers = [];
		const headersContents = [];
		const headerNodes = this.element.querySelectorAll('tr:first-of-type > th');
		for (const headerNode of headerNodes) {
			if (!containsNestedTables || headerNode.closest('table') === this.element) {
				headers.push(headerNode);
				headersContents.push(headerNode.innerHTML);
			}
		}
		const headersCount = headers.length;
		if (headersCount) {
			const cells = this.element.querySelectorAll('td:not(' + this.dataSelector(this.cellProcessedAttribute) + ')');
			let index = 0;
			for (const cell of cells) {
				if (!containsNestedTables || cell.closest('table') === this.element) {
					const nestedHeader = document.createElement('div');
					nestedHeader.innerHTML = headersContents[index];
					this.dataAttr(nestedHeader).set(this.nestedHeaderAttribute, '');
					this.dataAttr(cell).set(this.cellProcessedAttribute, '');
					// if the cell is empty, there is no reason to add a header for it
					if (cell.firstChild) {
						cell.insertBefore(nestedHeader, cell.firstChild);
					}
					index = (index + 1) % headersCount;
				}
			}
		}
	}

}


export default UserGeneratedTable;
