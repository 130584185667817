import ClassListParser from './class-list-parser';
import ClassList from './class-list';
import CssData from './css-data';
import DataAttrParser from './data-attr-parser';
import DataAttr from './data-attr';
import IntersectionObservers from './intersection-observers';
import ThreeStateTransition from './three-state-transition';
import domMixin from './dom-mixin';


export default (di) => {
	di
		.setType({type: DataAttrParser, name: 'DataAttrParser', params: {
				prefix: di.lazyValue('dom/dataAttrPrefix', '')
			}
		})
		.set('dom/dataAttrParser', di.lazyNew('DataAttrParser'))

		.setType({type: DataAttr, name: 'DataAttr', params: {
				parser: di.lazyGet('dom/dataAttrParser')
			}
		})
		.set('dom/dataAttrFactory', di.newFactory('DataAttr'))

		.setType({type: CssData, name: 'CssData'})
		.set('dom/cssDataFactory', di.newFactory('CssData'))

		.setType({type: ClassListParser, name: 'ClassListParser', params: {
				prefix: di.lazyValue('dom/classPrefix', ''),
				basePrefix: di.lazyValue('dom/baseClassPrefix', '')
			}
		})
		.set('dom/classListParser', di.lazyNew('ClassListParser'))

		.setType({type: ClassList, name: 'ClassList', params: {
				parser: di.lazyGet('dom/classListParser')
			}
		})
		.set('dom/classListFactory', di.newFactory('ClassList'))

		.setType({type: IntersectionObservers, name: 'IntersectionObservers'})
		.set('dom/intersectionObservers', di.lazyNew('IntersectionObservers'))


		.setMixin({mixin: domMixin, name: 'domMixin', setters: {
				injectEvents: di.lazyGet('events/events'),
				injectDataAttrParser: di.lazyGet('dom/dataAttrParser'),
				injectDataAttrFactory: di.lazyGet('dom/dataAttrFactory'),
				injectCssDataFactory: di.lazyGet('dom/cssDataFactory'),
				injectClassListParser: di.lazyGet('dom/classListParser'),
				injectClassListFactory: di.lazyGet('dom/classListFactory'),
				injectThreeStateTransitionFactory: di.lazyGet('dom/threeStateTransition')
			}
		})

		.setType({type: ThreeStateTransition, name: 'ThreeStateTransition', params: {
				classListFactory: di.lazyGet('dom/classListFactory')
			}
		})

		.set('dom/threeStateTransition', (element, animatedElement = null) => di.newInstance('ThreeStateTransition', {element: element, animatedElement: animatedElement}))
	;
};
